/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';

import ShadowImage from '../ShadowImage';
import Tooltip from '../Tooltip';

import { shadowText } from '../../utils/misc';

import UnknownApp from '../../assets/unknown-app.svg';
import AndroidIcon from '../../assets/android-brands.svg';
import AppleIcon from '../../assets/apple-brands.svg';

import './AppIcon.css';

const AppIcon = ({
  app,
  showStore,
  showTooltip,
  isShadow,
}) => {
  if (!app) return null;

  const {
    icon_url,
    title,
    device,
  } = app;
  let icon = null;

  if (device === 'ios') {
    icon = AppleIcon;
  } else if (device === 'android') {
    icon = AndroidIcon;
  }

  return (
    <div className={`appicon ${!app ? 'unknown' : ''}`}>
      <Tooltip
        stayClose={!showTooltip}
        position="right"
        tooltip={shadowText({
          str: title,
          alt: 'App',
          isShadow,
        })}
      >
        <span className="appicon-wrapper">
          <ShadowImage
            isShadow={isShadow}
            src={icon_url}
            alt={title}
          />
        </span>
      </Tooltip>
      {showStore && icon && <img className="app-device" src={icon} alt={device} />}
    </div>
  );
};

export const AppIconPlaceholder = () => (
  <div className="appicon unknown">
    <span className="appicon-wrapper" />
  </div>
);

AppIcon.propTypes = {
  app: PropTypes.shape(),
  showTooltip: PropTypes.bool,
  showStore: PropTypes.bool,
  isShadow: PropTypes.bool,
};

AppIcon.defaultProps = {
  app: {
    icon_url: UnknownApp,
    title: 'Unknown App',
    device: null,
    unknown: true,
  },
  showTooltip: true,
  showStore: true,
  isShadow: false,
};

export default AppIcon;

// FIXME: We need white shadow for device icon.
